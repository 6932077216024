<template>
    <div class="h-full pb-10">
        <div class="flex gap-2 items-center">
            <h3 class="text-xl font-bold">{{$route.name}}</h3>
        </div>

        <div class="content bg-white shadow rounded mt-2 p-3 h-full">
            <a-select class="w-full" placeholder="Select a PO..." @change="selectPo">
                <a-select-option v-for="order in orders" :key="order.pkPurchaseID" :value="order.pkPurchaseID">{{order.ExternalInvoiceNumber}}</a-select-option>
            </a-select>

            <div v-if="currentOrder">
                <div class="overflow-x-auto">
                    <table class="w-full mt-3">
                        <thead>
                            <tr>
                                <th class="px-1 py-2 bg-gray-50 border-b border-t border-l border-r border-gray-200">PO</th>
                                <th class="px-1 py-2 bg-gray-50 border-b border-t border-r border-gray-200">Supplier Reference</th>
                                <th class="px-1 py-2 bg-gray-50 border-b border-t border-r border-gray-200">Status</th>
                                <th class="px-1 py-2 bg-gray-50 border-b border-t border-r border-gray-200">Date of Purchase</th>
                                <th class="px-1 py-2 bg-gray-50 border-b border-t border-r border-gray-200">Quoted Delivery Date</th>
                                <th class="px-1 py-2 bg-gray-50 border-b border-t border-r border-gray-200">Total Cost</th>
                                <th class="px-1 py-2 bg-gray-50 border-b border-t border-r border-gray-200">Currency</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td class="px-1 py-1 border-b border-l border-r border-gray-200">{{currentOrder.PurchaseOrderHeader.ExternalInvoiceNumber}}</td>
                                <td class="px-1 py-1 border-b border-r border-gray-200">{{currentOrder.PurchaseOrderHeader.SupplierReferenceNumber}}</td>
                                <td class="px-1 py-1 border-b border-r border-gray-200">{{currentOrder.PurchaseOrderHeader.Status}}</td>
                                <td class="px-1 py-1 border-b border-r border-gray-200">{{currentOrder.PurchaseOrderHeader.DateOfPurchase}}</td>
                                <td class="px-1 py-1 border-b border-r border-gray-200">{{currentOrder.PurchaseOrderHeader.QuotedDeliveryDate}}</td>
                                <td class="px-1 py-1 border-b border-r border-gray-200">{{currentOrder.PurchaseOrderHeader.TotalCost}}</td>
                                <td class="px-1 py-1 border-b border-r border-gray-200">{{currentOrder.PurchaseOrderHeader.Currency}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>


                <div class="overflow-x-auto">
                    <table class="w-full mt-3">
                        <thead>
                        <tr>
                            <th class="px-1 py-2 bg-gray-50 border-b border-t border-l border-r border-gray-200">SKU</th>
                            <th class="px-1 py-2 bg-gray-50 border-b border-t border-r border-gray-200">Title</th>
                            <th class="px-1 py-2 bg-gray-50 border-b border-t border-r border-gray-200">Quantity</th>
                            <th class="px-1 py-2 bg-gray-50 border-b border-t border-r border-gray-200">Cost</th>
                            <th class="px-1 py-2 bg-gray-50 border-b border-t border-r border-gray-200">Available</th>
                            <th class="px-1 py-2 bg-gray-50 border-b border-t border-r border-gray-200">Stock Level</th>
                            <th class="px-1 py-2 bg-gray-50 border-b border-t border-r border-gray-200">In Orders</th>
                            <th class="px-1 py-2 bg-gray-50 border-b border-t border-r border-gray-200">Minimum Level</th>
                            <th class="px-1 py-2 bg-gray-50 border-b border-t border-r border-gray-200">Consumption 30</th>
                            <th class="px-1 py-2 bg-gray-50 border-b border-t border-r border-gray-200">Consumption 60</th>
                            <th class="px-1 py-2 bg-gray-50 border-b border-t border-r border-gray-200">Consumption 90</th>
                            <th class="px-1 py-2 bg-gray-50 border-b border-t border-r border-gray-200">Consumption 120</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="item in items" :key="item.item.ItemNumber">
                            <td class="px-1 py-1 border-b border-l border-r border-gray-200">{{item.item.ItemNumber}}</td>
                            <td class="px-1 py-1 border-b border-r border-gray-200">{{item.item.ItemTitle}}</td>
                            <td class="px-1 py-1 border-b border-r border-gray-200">{{item.Quantity}}</td>
                            <td class="px-1 py-1 border-b border-r border-gray-200">{{item.item.RetailPrice}}</td>
                            <td class="px-1 py-1 border-b border-r border-gray-200">{{item.stock.StockLevels[0].Available}}</td>
                            <td class="px-1 py-1 border-b border-r border-gray-200">{{item.stock.StockLevels[0].StockLevel}}</td>
                            <td class="px-1 py-1 border-b border-r border-gray-200">{{item.stock.StockLevels[0].InOrderBook}}</td>
                            <td class="px-1 py-1 border-b border-r border-gray-200">{{item.stock.StockLevels[0].MinimumLevel}}</td>
                            <td class="px-1 py-1 border-b border-r border-gray-200">{{item.consumption.Consumption30}}</td>
                            <td class="px-1 py-1 border-b border-r border-gray-200">{{item.consumption.Consumption60}}</td>
                            <td class="px-1 py-1 border-b border-r border-gray-200">{{item.consumption.Consumption90}}</td>
                            <td class="px-1 py-1 border-b border-r border-gray-200">{{item.consumption.Consumption120}}</td>
                        </tr>
                        </tbody>
                    </table>
                </div>

                <div class="overflow-x-auto">
                    <table class="w-full mt-3">
                        <thead>
                            <tr>
                                <th class="px-1 py-2 bg-gray-50 border-b border-t border-l border-r border-gray-200">Supplier</th>
                                <th class="px-1 py-2 bg-gray-50 border-b border-t border-r border-gray-200">Code</th>
                                <th class="px-1 py-2 bg-gray-50 border-b border-t border-r border-gray-200">Supplier Code</th>
                                <th class="px-1 py-2 bg-gray-50 border-b border-t border-r border-gray-200">Lead Time</th>
                                <th class="px-1 py-2 bg-gray-50 border-b border-t border-r border-gray-200">Purchase Price</th>
                                <th class="px-1 py-2 bg-gray-50 border-b border-t border-r border-gray-200">Supplier Currency</th>
                                <th class="px-1 py-2 bg-gray-50 border-b border-t border-r border-gray-200">Supplier Min Order Qty</th>
                                <th class="px-1 py-2 bg-gray-50 border-b border-t border-r border-gray-200">Supplier Pack Size</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="item in items" :key="item.item.ItemNumber">
                                <td class="px-1 py-1 border-b border-l border-r border-gray-200">{{item.stock.Suppliers[0].Supplier}}</td>
                                <td class="px-1 py-1 border-b border-r border-gray-200">{{item.stock.Suppliers[0].Code}}</td>
                                <td class="px-1 py-1 border-b border-r border-gray-200">{{item.stock.Suppliers[0].SupplierBarcode}}</td>
                                <td class="px-1 py-1 border-b border-r border-gray-200">{{item.stock.Suppliers[0].LeadTime}}</td>
                                <td class="px-1 py-1 border-b border-r border-gray-200">{{item.stock.Suppliers[0].PurchasePrice}}</td>
                                <td class="px-1 py-1 border-b border-r border-gray-200">{{item.stock.Suppliers[0].SupplierCurrency}}</td>
                                <td class="px-1 py-1 border-b border-r border-gray-200">{{item.stock.Suppliers[0].SupplierMinOrderQty}}</td>
                                <td class="px-1 py-1 border-b border-r border-gray-200">{{item.stock.Suppliers[0].SupplierPackSize}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            <div v-else class="flex items-center justify-center h-full">
                <a-empty></a-empty>
            </div>
        </div>
    </div>
</template>

<script>
import {defineComponent} from "vue";
export default defineComponent({
    name: "POReview",
    data() {
        return {
            orders: [],
            currentOrder: null,
            items: []
        }
    },
    mounted() {
        this.$http.get('https://linnworks.darksidedev.net/api/getAllDraftPurchaseOrders').then(resp => {
            this.orders = resp.data.Result
        })
    },
    methods: {
        selectPo(e) {
            this.currentOrder = null;
            this.items = []
            this.$http.get('https://linnworks.darksidedev.net/api/getPurchaseOrderByID?POID=' + e).then(resp => {
                if(resp.data === "API calls quota exceeded! Maximum admitted 250 per Minute.") {
                    alert('Linnworks rate limit exceeded! Try again in a minute!')
                } else {
                    this.currentOrder = resp.data
                    const poItems = this.currentOrder.PurchaseOrderItem;
                    poItems.forEach(el => {
                        this.$http.get("https://linnworks.darksidedev.net/api/getItemDetailedByID?ID=" + el.fkStockItemId + "&SKU=" + el.SKU).then(res => {
                            res.data.Quantity = el.Quantity
                            this.items.push(res.data)
                            console.log(this.items)
                        })
                    })
                }
            })
        }
    }
});
</script>

<style scoped>

</style>
